/* eslint-disable react/prop-types */
import React from 'react';
import { Account_text } from '../../../constant/Translation/Account';
import ReadingComposer from '../../Shared/Composer/ReadingComposer';
import ALink from '../../../constant/ALink';

const AttributeDetails = ({ attributes }) => {
  return (
    <div className=" bg-white rounded-card   w-full min-h-[66px]    p-[16px] shadow-c-sm  ">
      <h2 className="heading-h2">{Account_text.storedetail.details}</h2>
      <div className="  mt-3 flex flex-col gap-2 ">
        {attributes?.map((attr) => {
          return (
            attr.visible &&
            attr.field_type !== 11 && (
              <div className="grid grid-cols-2 items-start  " key={attr.id}>
                <span className="flex items-center justify-start gap-2 ">
                  {attr?.icon_path && (
                    <img className=" w-8 h-8 " src={attr?.icon_path} alt="" />
                  )}
                  <p className="description-paragraph">{attr.name}</p>
                </span>

                {/* 6 */}
                {attr.field_type === 6 && (
                  <ReadingComposer
                    contents={attr.values[0]}
                    isReadOnly={true}
                  />
                )}

                {/* 1|2 */}
                {(attr.field_type === 1 || attr.field_type === 2) && (
                  <div className=" flex items-center justify-start gap-2 flex-wrap">
                    {attr.values.map((item) => {
                      if (attr.field_type === 1 || attr.field_type === 2) {
                        return attr?.show_in_filter ? (
                          <ALink href={`/a?attribute_value_id=${item?.id}`}>
                            <a className="description-paragraph text-primary hover:underline duration-300">
                              {item.name}
                            </a>
                          </ALink>
                        ) : (
                          <p className="overflow-auto  scrollbar-none   description-paragraph ">
                            {item.name}
                          </p>
                        );
                      }
                    })}
                  </div>
                )}

                {attr.field_type !== 1 &&
                  attr.field_type !== 2 &&
                  attr.field_type !== 6 && (
                    <p className="  whitespace-pre-line  description-paragraph  overflow-auto  scrollbar-none">
                      {attr.values
                        .map((item) => {
                          if (attr.field_type === 3 || attr.field_type === 4) {
                            return item;
                          } else if (attr.field_type === 12) {
                            return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                          } else {
                            return item;
                          }
                        })
                        .join(', ')}
                    </p>
                  )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default AttributeDetails;
